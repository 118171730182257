@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --tertiary: 0 0% 20% / 30%;
    --tertiary-foreground: 180 5% 60%;

    --danger: 0 63% 51%;
    --danger-foreground: 0 0% 98%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 180 13% 6%;
    --foreground: 0 0% 98%;

    --card: 180 13% 6%;
    --card-foreground: 0 0% 98%;

    --popover: 180 13% 6%;
    --popover-foreground: 180 5% 60%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --tertiary: 0 0% 20% / 30%;
    --tertiary-foreground: 180 5% 60%;

    --danger: 0 63% 51%;
    --danger-foreground: 0 0% 98%;

    --muted: 180 2% 50%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 180 5% 60% / 10%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 20%;
    --input: 180 5% 60% / 30%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 359 2% 90%;
    --chart-2: 240 1% 74%;
    --chart-3: 240 1% 58%;
    --chart-4: 240 1% 42%;
    --chart-5: 240 2% 26%;

    --chart-6: 173 58% 39%;
    --chart-7: 12 76% 61%;
    --chart-8: 197 37% 24%;
    --chart-9: 43 74% 66%;
    --chart-10: 27 87% 67%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  background-color: #0D1111;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}